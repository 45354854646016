
.Loading{
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(50, 53, 61, 0.33);
    border-radius: 4px;
    z-index: 50;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.circle{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 5px solid #777;
}

.circle{
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
    position: relative;
    top: 50%;
    left: 50%;
    /* border-color: #6c757d; */
    transform: translate(-50%, -50%);
    animation: spin 1s infinite forwards linear;
}

@keyframes spin {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}